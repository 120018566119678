<template>
  <div class="dialog">
    <div class="dialog-header">
      <p class="dialog-title text-uppercase mb-0">EMAIL INVITE</p>
    </div>
    <div class="dialog-content" style="overflow-y: auto; max-height: 560px;">
      <b-container class="mt-3" fluid>
        <b-form @submit.prevent="onSubmit">
          <b-form-group label="Invitee name: "
                        :label-cols="3"
                        label-for="name"
                        horizontal>
            <b-form-input id="name"
                          type="text"
                          aria-describedby="name-input"
                          placeholder="Enter Name"
                          autocomplete="off"
                          @input="$v.form.name.$touch()"
                          v-model.trim="$v.form.name.$model"
                          :state="!$v.form.name.$error">
            </b-form-input>
            <b-form-invalid-feedback id="name-input">
              First Name is required.
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="Invitee Email: "
                        :label-cols="3"
                        label-for="email"
                        horizontal>
            <b-form-input id="email"
                          type="email"
                          placeholder="Enter Email"
                          aria-describedby="email-input"
                          autocomplete="nope"
                          v-model.trim="$v.form.email.$model"
                          @input="$v.form.email.$touch()"
                          :state="!$v.form.email.$error">
            </b-form-input>
            <b-form-invalid-feedback id="email-input">
              <span v-if="!$v.form.email.required">Email is required.</span>
              <span v-if="!$v.form.email.email && $v.form.email.required">
                This requires a valid email address.
              </span>
              <span v-if="!$v.form.email.uniqueEmail && $v.form.email.email &&
                      $v.form.email.required">
                Email is already registered.
              </span>
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="Role: "
                        :label-cols="3"
                        label-for="role"
                        horizontal>
            <b-form-select id="role"
                           aria-describedby="role-input"
                           :options="options"
                           :state="!$v.form.userType.$error"
                           v-model.trim="$v.form.userType.$model"
                           @input="$v.form.userType.$touch()" />
            <b-form-invalid-feedback id="role-input">
              Please Select a Role.
            </b-form-invalid-feedback>
          </b-form-group>
          <div class="d-flex justify-content-end">
            <b-button class="text-center"
                      type="submit"
                      variant="outline-success"
                      :disabled="$v.$invalid || response.status === 'pending'">
              Submit
            </b-button>
            <b-button class="text-center ml-1 "
                      variant="outline-danger"
                      @click="closeModal">
              Cancel
            </b-button>
          </div>
        </b-form>
      </b-container>
    </div>

    <!-- Loading -->
    <loading :active.sync="isLoading"
             :is-full-page="true"
             background-color="#000"
             color="#068bcd"
             :opacity="0.3"
             :width="70"
             :height="70">
    </loading>
  </div>
</template>
<script>
  const Loading = () => import('vue-loading-overlay');

  import { required, email } from 'vuelidate/lib/validators';
  import 'vue-loading-overlay/dist/vue-loading.css';

  export default {
    name : 'SendInvite',
    data() {
      return {
        isLoading : false,
        form      : {
          name     : null,
          email    : null,
          userType : null,
        },
        options : [
          {
            value : null,
            text  : 'Please select an option',
          },
          {
            value : 4,
            text  : 'Champion',
          },
          {
            value : 5,
            text  : 'Influencer',
          },
        ],
        response : {
          status : null, // API response status
        },
        uniqueEmail : true,
      };
    },
    validations : {
      form : {
        name : {
          required,
        },
        email : {
          required,
          email,
          uniqueEmail() {
            return this.uniqueEmail;
          },
        },
        userType : {
          required,
        },
      },
    },
    components : {
      Loading,
    },
    computed : {
      registrationLink : function () {
        return process.env.VUE_APP_BASE_URL + '/registration-invite' +
          '/' + this.$store.getters['user/user'].user_id + '/' +
          this.form.email + '/' + this.form.userType + '/';
      },

      formEmail : function () {
        return this.form.email;
      },
    },
    watch : {
      formEmail : function () {
        this.uniqueEmail = true;
        this.checkEmail();
      },
    },
    methods : {

      /**
       * Check if Email is already registered
       */
      // eslint-disable-next-line no-undef
      checkEmail : _.debounce(function () {
        this.$http.get('api/user/email', {
          params : {
            email : this.form.email,
          },
        }).then(response => {
          // swapped value since api returns true
          //  if email exists hence email is not unique
          this.uniqueEmail = (response.data) ? false : true;
        });
      }, 500),

      /**
       * Send Invite
       */
      onSubmit() {
        this.response.status = 'pending';
        this.isLoading = true;
        this.$http.post('auth/registrationInvite', {
          name     : this.form.name,
          to       : this.form.email,
          url      : this.registrationLink,
          roleType : this.form.userType,
          userId   : this.$store.getters['user/user'].user_id,
        }).then(() => {
          this.response.status = null;
          this.$notify({
            group : 'notif',
            type  : 'success',
            title : 'Success!',
            text  : 'Email Invite successfully sent!',
          });
          const that = this;
          setTimeout(async function () {
            that.isLoading = false;
            that.$emit('close');
          }, 2000);
        }).catch(() => {
          this.response.status = null;
          this.isLoading = false;
          this.$notify({
            group : 'notif',
            type  : 'error',
            title : 'Failed!',
            text  : 'Oops! Something went wrong.',
          });
        });
      },

      /**
       * Close 'Send Invite' Modal
       */
      closeModal() {
        this.$emit('close');
      },
    },
  };
</script>
<style>
</style>
